import * as React from "react"
import { useState, memo } from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/layout/Seo"
import { ProductImage } from "../components/products/CategoryImage"
import { IconArrowForward, IconCirclePlus, IconPlace, IconRing } from "../components/icons/Icons"
import { getLabel, getValue } from "../helpers/StringHelpers"

import { USD } from "../helpers/NumberHelper"
import { useDispatch } from "react-redux"
import { addProduct } from "../state/cart"
import { SimilarProducts } from "../components/products/SimilarProducts"

const redirectToCanonical = canonical => {
  if (typeof window !== "undefined" && window && window.location.pathname !== canonical) {
    window.history.replaceState(null, null, canonical)
  }
}

const ProductTitleC = ({ info }) => {
  const additional_info = []
  const subheading = []
  if (info.model) {
    subheading.push(info.model)
  }
  if (info.nickname) {
    subheading.push(`${info.nickname}`)
  }
  if (info.model_no) {
    subheading.push(`${info.model_no}`)
  }
  if (info.case_size_mm) {
    if (info.case_length && info.case_size_mm !== info.case_length) {
      additional_info.push(`${info.case_size_mm}x${info.case_length}mm`)
    } else {
      additional_info.push(`${info.case_size_mm}mm`)
    }
  }
  if (info.movement) {
    additional_info.push(`${info.movement}`)
  }
  if (info.head_metal) {
    additional_info.push(`${info.head_metal}`)
  }
  if (info.band_type) {
    additional_info.push(`${info.band_type}`)
  }
  const additional_info_tag = additional_info ? (
    <b className={"mt-4 text-sm block"}>{additional_info.join(", ")}</b>
  ) : null

  return (
    <h1 className={"text-md"}>
      {info.brand && info.model ? (
        <>
          <b className={"text-2xl font-bold block"}>{info.brand}</b>
          <strong className={"text-lg font-bold block"}>{subheading.join(", ")}</strong>
          {additional_info_tag}
        </>
      ) : (
        <b className={"text-2xl font-bold"}>{info.title}</b>
      )}
    </h1>
  )
}
const ProductTitle = memo(ProductTitleC)

const ProductDetailsPage = ({ data }) => {
  const categories = data.category?.breadcrumbs
  const recommendedProductsFromContext = data.recReads
  const info = data.item

  redirectToCanonical("/" + info.url_key + "/")
  const images = info.media_gallery || []
  const dispatch = useDispatch()
  const [expanded, setExpanded] = useState(false)
  const [currentImage, setCurrentImage] = useState(images[0])
  const allDetails = [
    "head_metal",
    "case_size_mm",
    "case_length",
    "case_shape",
    "case_back",
    "band_color",
    "band_size",
    "band_width",
    "bezel_material",
    "buckle_style",
    "original_box",
    "original_papers",
    "age",
    "band_color",
    "band_length",
    "band_metal",
    "band_size",
    "band_type",
    "band_width",
    "bezel_material",
    "booklets",
    "brand",
    "buckle_material",
    "buckle_style",
    "case_thickness",
    "condition",
    "dial_style",
    "dial",
    "features",
    "gender",
    "lug_to_lug_length",
    "model_no",
    "model",
    "movement",
    "serial_number",
    "warranty",
    "size",
    "watch_style",
  ]
  const shortDetails = [
    "head_metal",
    "case_size_mm",
    "case_length",
    "case_shape",
    "case_back",
    "band_color",
    "band_size",
    "band_width",
    "bezel_material",
    "buckle_style",
    "original_box",
    "original_papers",
  ]
  const attributes = []
  const shortAttributes = []
  for (const prop of allDetails) {
    if (info[prop] && info[prop] !== "" && info[prop] !== "N/A" && info[prop] !== "NA") {
      attributes.push(prop)
    }
  }
  for (const prop of shortDetails) {
    if (info[prop] && info[prop] !== "" && info[prop] !== "N/A" && info[prop] !== "NA") {
      shortAttributes.push(prop)
    }
  }

  const selectedAttributes = expanded ? attributes : shortAttributes
  const productType = info.productType

  const getQuoteByType = productType => {
    switch (productType) {
      case "watch":
        return "/what-is-my-watch-worth"
      case "jewelry":
        return "/what-is-my-jewelry-worth"
      case "diamond":
        return "/what-is-my-diamond-worth/"
      case "silver":
        return "/what-is-my-sterling-silver-worth"
      default:
        return "/what-are-you-looking-to-sell/" // or handle the default case as needed
    }
  }

  return (
    <Layout
      breadcrumbs={data.category?.breadcrumbs}
      title={info.title}
      canonical={"/" + info.url_key + "/"}
      shop={true}
    >
      <Seo
        title={info.meta_title}
        canonical={"/" + info.url_key + "/"}
        product={info}
        description={info.sku + " " + info.meta_description}
      />

      <div className={"flex flex-col md:flex-row w-11/12 max-w-[1366px] mx-auto"}>
        <aside
          className={
            "h-[540px] xl:h-[680px] w-40 overflow-auto hidden flex-shrink-0 flex-grow-0 flex-col gap-2 lg:flex"
          }
        >
          {images.map((k, v) => {
            return (
              <div
                key={"image-" + v}
                className={
                  "align-center h-32 w-32 p-4 " +
                  (k === currentImage ? "border-2 border-accent" : "border border-gray-400")
                }
              >
                <button
                  onClick={e => {
                    e.preventDefault()
                    setCurrentImage(k)
                  }}
                >
                  <ProductImage
                    image={k}
                    title={info.title}
                    alt={info.title}
                    type={info.productType}
                    size={360}
                    sku={info.sku}
                  />
                </button>
              </div>
            )
          })}
        </aside>

        <div className={"w-full max-w-max p-4 flex justify-center items-center  "}>
          <ProductImage
            image={currentImage}
            title={info.title}
            alt={info.title}
            sku={info.sku}
            type={info.productType}
          />
        </div>
        <div className={"flex w-full items-center md:w-1/2"}>
          <div className={"w-full text-center md:text-left lg:m-16 xl:ml-32"}>
            <div className={"text-sm uppercase"}>
              Pre-Owned {info.sku[0] === "W" ? "Watch" : "Jewelry"} Retailer
            </div>
            <ProductTitle info={info} />
            {info.price ? (
              <>
                <div className={"my-4 text-gray-500"}>
                  Est. Sale Price
                  <br />
                  <span className="text-xl text-black">
                    <b>{USD(info.price)}</b>
                  </span>
                </div>
                <div
                  className={
                    "items-center flex flex-row justify-around md:justify-start w-full gap-3"
                  }
                >
                  <Link
                    to={getQuoteByType(productType)}
                    className="w-36 flex justify-center items-center text-sm border-2 border-accent hover:border-accentMd bg-accent py-3 font-bold text-white duration-200 hover:bg-accentMd hover:text-white "
                  >
                    SELL TO US
                  </Link>
                  <button
                    className={
                      "w-36 text-sm border-2 border-gray-400 hover:bg-gray-400 py-3 font-bold hover:text-white duration-200 bg-white text-gray-400 "
                    }
                    onClick={() =>
                      // Redirect to Gray and Sons
                      (window.location.href =
                        "https://www.grayandsons.com/" +
                        window.location.pathname.replace(/\/$/, ".html"))
                    }
                  >
                    BUY FROM US
                  </button>
                  {/*<IconFav className={'m-6 text-black'} />*/}
                </div>
              </>
            ) : (
              ""
            )}
            <div className={"border-b border-solid border-accent"}>&nbsp;</div>
            <div className={"mt-8"}>
              <a className={"text-md my-2 block"} href={"tel:+13057706955"}>
                <IconRing /> Call: 305.770.6955
              </a>
              <a
                className={"text-md my-2 block"}
                href={
                  "https://www.google.com/maps/search/?api=1&query=9595%20Harding%20Ave%2C%20Surfside%2C%20FL%2C%2033154&ll=Gray%20&%20Sons"
                }
              >
                <IconPlace /> Visit Our Showroom
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={"w-full flex-wrap md:flex max-w-[1366px] mx-auto"}>
        <div className={"flex flex-row w-11/12 mx-auto md:mt-16 "}>
          <div className={"w-full mx-auto bg-box p-8"}>
            <h2 className={"border-b-4 border-black text-2xl mb-4"}>Product Details</h2>
            <p
              className={"text-sm"}
              dangerouslySetInnerHTML={{
                __html: info.description.replace(
                  /\/media\/gia-certificates\//g,
                  "https://www.grayandsons.com/media/gia-certificates/"
                ),
              }}
            />
            {
              <>
                <div className={"text-sm sm:flex"}>
                  <div className={"sm:w-1/2"}>
                    {(expanded ? attributes : shortAttributes).map((v, i) => {
                      return i >= selectedAttributes.length / 2 ? (
                        <></>
                      ) : (
                        <div key={v} className={"my-4"}>
                          <strong>{getLabel(v)}: </strong>
                          {getValue(v, info[v])}
                        </div>
                      )
                    })}
                  </div>
                  <div className={"md:w-1/2"}>
                    {selectedAttributes.map((v, i) => {
                      return i < selectedAttributes.length / 2 ? (
                        <></>
                      ) : (
                        <div key={v} className={"my-4"}>
                          <strong>{getLabel(v)}: </strong>
                          {getValue(v, info[v])}
                        </div>
                      )
                    })}
                  </div>
                </div>
              </>
            }

            <button
              onClick={() => {
                setExpanded(!expanded)
              }}
              className={"text-underline"}
            >
              <IconCirclePlus />
              {expanded ? "Hide" : "View"} all product details
            </button>
          </div>
          <div className={"hidden h-auto lg:block relative w-full"}>
            <div className="sticky top-0">
              <ProductImage
                image={images[images.length - 2]}
                title={info.title}
                alt={info.title}
                sku={info.sku}
                className={"w-full object-contain"}
              />
            </div>
          </div>
        </div>

        {categories && (
          <div className={"w-11/12 flex flex-col lg:flex-row bg-box py-20 max-w-[1366px] mx-auto "}>
            <div className={"flex w-full lg:w-2/12 flex-col items-center justify-center order-1"}>
              <h3 className={"text-2xl font-bold"}>Past Purchases...</h3>
              {data.category &&
              data.category.breadcrumbs &&
              data.category.breadcrumbs.length > 0 ? (
                <Link
                  to={"/" + data.category.breadcrumbs[0].url}
                  className={
                    "py-2 transition ease-in hover:translate-x-2 flex flex-row justify-center items-center gap-2 h-10"
                  }
                >
                  Browse Category <IconArrowForward className={"h-3 w-3"} />
                </Link>
              ) : (
                ""
              )}
            </div>
            <div
              className={"mb-8 flex w-10/12  gap-4 overflow-hidden pb-8 md:mb-16 order-2 mx-auto"}
            >
              <SimilarProducts recommendedProducts={recommendedProductsFromContext} />
            </div>
          </div>
        )}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($url: String!, $categoryId: Int!, $recProductsUrls: [String!]!) {
    item: strapiProduct(url_key: { eq: $url }) {
      productType
      id
      price
      name
      authenticity
      band_metal
      description
      age
      ask_for_price
      band_color
      band_length
      band_size
      band_type
      band_width
      bezel_material
      booklets
      brand
      buckle_material
      buckle_style
      case_back
      case_length
      case_material
      case_shape
      case_size
      case_size_mm
      case_thickness
      categories {
        name
        level
      }
      condition
      dial_style
      dial
      diamond_weight
      diamond_weight_attribute
      diamonds
      price_3rd
      title
      features
      gender
      gender_mfu
      head_metal
      image {
        name
        url
      }
      item_type
      lug_to_lug_length
      manufacturer
      media_gallery {
        name
        url
      }
      meta_description
      meta_title
      metals
      model
      model_no
      movement
      on_hold
      original_box
      original_papers
      price_filter
      qty
      retail_price
      search_terms
      serial_no
      serial_number
      short_name
      sku
      special_price
      special_to_date
      status
      small_image {
        name
        url
      }
      thumbnail {
        name
        url
      }
      url_key
      warranty
      size
      watch_style
    }
    recReads: allStrapiProduct(filter: { url_key: { in: $recProductsUrls } }) {
      edges {
        node {
          brand
          categories {
            name
          }
          small_image {
            name
            url
          }
          thumbnail {
            name
            url
          }
          name
          model
          price
          sku
          url_key
          title
        }
      }
    }
    category: strapiCategory(strapiId: { eq: $categoryId }) {
      breadcrumbs {
        name
        url
      }
    }
  }
`
export default ProductDetailsPage
